import { Fragment } from 'react'

import MarkdownJsx from 'markdown-to-jsx'

import { ContentTypeView } from 'utility/utility'

import { MarkdownLink } from './elements/MarkdownLink'
import { MarkdownParagraph } from './elements/MarkdownParagraph'
import { MarkdownImage } from './elements/MarkdownImage'

export const Markdown: ContentTypeView<{children: string}> =
    ({
        siteContext,
        pageContext,
        articleContext,
        children
    }) =>
        <MarkdownJsx
            options={{
                forceBlock: true,
                wrapper: Fragment,
                namedCodesToUnicode: {
                    lsquo: '‘',
                    rsquo: '’',
                    ldquo: '“',
                    rdquo: '”'
                },
                overrides: {
                    p: {
                       component: MarkdownParagraph,
                       props: { siteContext, pageContext, articleContext }
                    },
                    a: {
                        component: MarkdownLink,
                        props: { siteContext, pageContext, articleContext }
                    },
                    img: {
                        component: MarkdownImage,
                        props: { siteContext, pageContext, articleContext }
                    }
                }
            }}>
            {children}
        </MarkdownJsx>
