import type { DetailedHTMLProps, ReactElement } from 'react'

import { ContentTypeView } from 'utility/utility'
import { MarkdownLink } from './MarkdownLink'

export type MarkdownParagraphProps = Omit<DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>, 'ref'>

const isAnchor = (element: ReactElement): element is React.ReactElement<React.AnchorHTMLAttributes<HTMLAnchorElement>> =>
    element.type === 'a'

export const MarkdownParagraph: ContentTypeView<MarkdownParagraphProps> = ({
    siteContext, pageContext, articleContext,
    ...props
}) => {

    const children = props.children instanceof Array ? props.children : [props.children]

    if ( children.length === 1 ) {

        const element = children[0] as ReactElement | undefined | null

        if (
            element !== null
            && element !== undefined
            && isAnchor(element)
            && ( element.props.href?.startsWith('form:') ?? false )
        ) {
            return  <MarkdownLink
                        {...(element.props)}
                        siteContext={siteContext}
                        pageContext={pageContext}
                        articleContext={articleContext}
                    />
        }
        else {
            return <p {...props}/>
        }

    }
    else {
       return <p {...props}/>
    }

}
