import type { DetailedHTMLProps } from 'react'

import { ContentTypeView, strapiPublicImage } from 'utility/utility'

export type MarkdownImageProps = Omit<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>

export const MarkdownImage: ContentTypeView<MarkdownImageProps> = ({
    src,
    alt,
    ...img
}) =>
    <picture>
        <img
            {...img}
            src={ src === undefined ? '': strapiPublicImage(src) }
            alt={ alt === undefined ? '' : alt }
        />
    </picture>
